import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { colors, bp } from '../styles/colors'
import { MediumTitle, GreyText} from '../styles/fonts'
import { Link } from 'gatsby'
import BackButton from '../components/backButton'

const Laskutus = () => {
    return(
        <Layout>
            <SectionWrapper>
                <ButtonContainer><BackButton to={'/'}/></ButtonContainer>
                <Title>Laskutustiedot</Title>
                <PContainer>
                    <MediumTitle>Arvoisa toimittajamme ja yhteistyökumppani <br/></MediumTitle>
                    <P><br></br>Siirrymme sähköisten ostolaskujen käsittelyyn <Span>01.12.2021 alkaen</Span>.</P>
                    <P>
                        Pyydämme teitä jatkossa lähettämään Ylitalon rakennuspalvelu Oy:tä (y-tunnus 2905374-7) koskevat laskut <br/>
                        verkkolaskuosoitteeseen: <Span>003729053747</Span> <br/>
                        operaattori: <Span>Pagero</Span> <br/>
                        välittäjän tunnus: <Span>003723609900</Span>
                    </P>
                    <Span></Span>
                    <P>
                        Pidättehän sähköisten laskujen OVT-reitityksen voimassa, mikäli olemme tilanneet LVISNet-liittymän Admicomiin.
                        Mikäli käytössänne on paperinen laskutus, pyydämme teitä lähettämään laskut 01.12.2021 alkaen alla olevaan osoitteeseen. 
                        Huomioittehan että alla olevat tiedot tulee näkyä sellaisenaan skannattavassa laskukuvassa, tiedot pelkässä kirjekuoressa eivät riitä.
                    </P>
                    <P>
                        <Span>
                            Ylitalon rakennuspalvelu Oy <br/>
                            OVT 003729053747 <br/>
                            PL 908 <br/>
                            02066 DOCUSCAN
                        </Span>
                    </P>
                    <P></P>
                    <P>
                        Sähköpostilaskuille tulee vastaanottaja merkitä samaan tapaan kuin paperilaskuille.
                        Sähköpostilla toimitettavat ostolaskut lähetetään aina osoitteeseen <Mail href="mailto:admicom@xbs-salo.com">admicom@xbs-salo.com</Mail>. <br/>
                        Sähköpostilla toimitettavien ostolaskujen yhteydessä on huomioitava:
                        <Li>Osoitteeseen voi lähettää ainoastaan laskuja, ei muuta postia.</Li>
                        <Li>Laskun tulee olla PDF-formaatissa.</Li>
                        <Li>
                            Yhdessä sähköpostissa voi olla vain yhden laskun tiedot. 
                            Kaikkien laskun sivujen täytyy olla yhdessä PDF-tiedostossa, 
                            joten laskun mahdolliset liitteet skannataan samaan PDF-tiedostoon laskun jatkosivuiksi. 
                            Siten sähköposti voi sisältää ainoastaan yhden PDF-tiedoston.
                        </Li>
                        <Li>PDF-tiedosto ei saa olla salattu tai salasanalla suojattu.</Li>
                    </P>
                    <P>
                        Toimittajat voivat myös kirjata laskut suoraan Pageron portaaliin osoitteessa: <A to={"https://www.pagero.fi/projektisivut/admicom/"}>https://www.pagero.fi/projektisivut/admicom/</A>
                        <Li>Toimittajan tulee klikata sivuilla olevaa FREE WEB PORTAL -linkkiä, mistä pääsee rekisteröitymään ja saa tunnukset omaan sähköpostiinsa.</Li>
                        <Li>Sen jälkeen laskun voi luoda kirjautumalla sisään portaaliin ja lasku liikkuu siitä eteenpäin normaalina verkkolaskuna.</Li>
                    </P>
                    <P>Mahdollinen koontilaskutus tulee purkaa.</P>
                    <P>
                        Laskujenne asianmukaisen ja nopean käsittelyn varmistamiseksi pyydämme teitä tekemään tarvittavat muutokset 
                        Ylitalon rakennuspalvelu Oy:tä (y-tunnus 2905374-7) koskeviin tietoihinne. Vain em. osoitteeseen lähetetyt ostolaskut käsitellään. 
                        Maksumuistutukset, tiedotteet ja muu posti lähetetään edelleen postiosoitteeseemme.
                    </P>
                    <P>
                        <Span>
                            Ylitalon rakennuspalvelu Oy <br/>
                            Ilmakantie 28 <br/>
                            16310 VIRENOJA <br/>
                        </Span>
                    </P>
                </PContainer>
            </SectionWrapper>
        </Layout>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    min-width: 80%;
    @media screen and (max-width: ${bp.mobile}) {
        margin-top: 14rem;
        max-width: 90%;
    }
    
`

const SectionWrapper = styled.div`
    margin: auto;
    max-width: 80%;
    @media screen and (max-width: ${bp.mobile}) {
        max-width: 90%;
    }
`
const Title = styled(MediumTitle)`
    margin-top: 0rem;
    margin-bottom: 2rem;
    @media screen and (max-width: ${bp.mobile}) {
        margin-top: 1rem;
        
        font-size: 2rem;
    }
`
const PContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 4rem auto;
    max-width: 600px;
`
const Span = styled.span`
    color: ${colors.grey2};
    font-weight: 500;
`
const Li = styled.li`
    list-style: inside;
    list-style-position: inside;
    text-indent: -1.4em;
  padding-left: 1.4em;
`

const A = styled(Link)`
    color: ${colors.grey2};
    &:hover{
        color: ${colors.grey1} 
    }
`
const Mail = styled.a`
    color: ${colors.grey2};
    cursor: pointer;
    &:hover {
        color: ${colors.grey1};
    }
`
const P = styled(GreyText)`
    color: ${colors.grey3};
`

export default Laskutus